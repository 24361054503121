import React from "react";
import Footer from "../components/Footer";
import dashboardMap from "../assets/images/dashboard-map.jpg"
import dashboardTimeseries from "../assets/images/dashboard-timeseries.jpg"

import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="main_page_container">

        <div className="para_content">

          <div className="card_container">

            <div className="card_item">
              <div className="card_content">
                <p>
                The flood atlas of India is based on the gridded simulations of flood dynamics. The atlas provides a glimpse of past annual maximum flooded area during 1901-2020.
                </p>

              </div>
              <div className="card_img">
                <img src={dashboardMap} alt="dashboard-img" />

              </div>

              <div className="card_btn">
                <Link to="/annual-max-flood"> <button>Annual Flood Map</button></Link>
              </div>
            </div>

          </div>
          <div className="card_container">
            <div className="card_item">
              <div className="card_content">
                <p>
                  We estimated flood risk using hazard, exposure, and vulnerability based on the common framework adopted by the United Nations in the Global Assessment Reports. The flood risk assessment can help identify the hotspots and prioritize climate adaptation</p>

              </div>
              <div className="card_img">
                <img src={dashboardTimeseries} alt="dashboard-img" />

              </div>

              <div className="card_btn">
                <Link to="/flood-risk"> <button>Flood Risk Maps</button></Link>
              </div>
            </div>
          </div>


        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
