import React, { Suspense, lazy } from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import NavbarMain from './components/NavbarMain'

import ScrollToTop from './components/ScrollToTop'
import Preloader from './components/Preloader'
import { AlertProvider } from './context/AlertContext'
import AlertMessage from './components/AlertMessage'



const FloodRiskPage = lazy(() => import('./pages/FloodRiskPage'));
const MaxFloodPage = lazy(() => import('./pages/MaxFloodPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const ContactUsPage = lazy(() => import('./pages/ContactUsPage'));
const AnnualFloodPage = lazy(() => import('./pages/AnnualFloodPage'));


const App = () => {
  return (
    <Router>
      <AlertProvider>
       <NavbarMain/>
       <AlertMessage />
       <Suspense fallback={<Preloader />}>

      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/flood-risk' element={<FloodRiskPage/>}/>
        <Route path='/historical-max-flood' element={<MaxFloodPage/>}/>
        <Route path='/annual-max-flood' element={<AnnualFloodPage/>}/>
        {/* <Route path='/map-viewer' element={<Dashboard/>}/> */}
        <Route path='/about' element={<AboutPage/>}/>
        {/* <Route path='/timeseries' element={<TimeseriesPage/>}/> */}
        <Route path='/contact' element={<ContactUsPage/>}/>
        
      </Routes>

      </Suspense>
        <ScrollToTop/>
        </AlertProvider>
    </Router>
    
  )
}

export default App